<template>
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.5683 5.25893C13.3216 4.9456 12.8549 4.91893 12.5749 5.19893L10.5749 7.19894C10.7216 7.63893 10.7483 8.1456 10.6149 8.67227C10.3816 9.61227 9.62159 10.3723 8.68159 10.6056C8.15493 10.7389 7.64826 10.7123 7.20826 10.5656C7.20826 10.5656 6.25493 11.5189 5.56826 12.2056C5.23493 12.5389 5.34159 13.1256 5.78826 13.2989C6.50159 13.5723 7.24159 13.7123 8.00159 13.7123C9.18826 13.7123 10.3416 13.3656 11.3949 12.7189C12.4683 12.0523 13.4349 11.0723 14.2149 9.8256C14.8483 8.81893 14.8149 7.1256 14.1816 6.11894C13.9883 5.81227 13.7816 5.5256 13.5683 5.25893Z"
			fill="#6E6E82" />
		<path
			d="M6.65375 9.34708C6.31375 9.00042 6.09375 8.52042 6.09375 8.00042C6.09375 6.95375 6.94708 6.09375 8.00042 6.09375C8.52042 6.09375 9.00042 6.31375 9.34708 6.65375L6.65375 9.34708Z"
			fill="#6E6E82" />
		<path
			d="M9.90732 6.09317C9.42065 5.59983 8.74732 5.3065 8.00065 5.3065C6.50732 5.3065 5.30732 6.51317 5.30732 7.99983C5.30732 8.7465 5.60732 9.41983 6.09398 9.9065L3.84065 12.1665H3.83398C3.09398 11.5665 2.41398 10.7998 1.83398 9.89317C1.16732 8.8465 1.16732 7.1465 1.83398 6.09983C2.60732 4.8865 3.55398 3.93317 4.60732 3.27983C5.66065 2.63983 6.81398 2.2865 8.00065 2.2865C9.48732 2.2865 10.9273 2.83317 12.1673 3.83317L9.90732 6.09317Z"
			fill="#6E6E82" />
		<path
			d="M7.9981 9.90538C7.9581 9.90538 7.92477 9.90538 7.88477 9.89204L9.89143 7.88538C9.90477 7.92538 9.90477 7.95871 9.90477 7.99871C9.90477 9.04538 9.05143 9.90538 7.9981 9.90538Z"
			fill="#6E6E82" />
		<path
			d="M13.7859 1.48594L1.48594 13.7926C1.28594 13.9926 1.28594 14.3193 1.48594 14.5193C1.58594 14.6126 1.7126 14.6659 1.84594 14.6659C1.97927 14.6659 2.10594 14.6126 2.20594 14.5126L14.5126 2.20594C14.7193 2.00594 14.7193 1.68594 14.5126 1.48594C14.3126 1.28594 13.9859 1.28594 13.7859 1.48594Z"
			fill="#6E6E82" />
	</svg>
</template>

<script>
export default {
	name: 'EyeSlash',
};
</script>

<style></style>
